.shevchenko-page {
    background: #F8F8F8;
    min-height: calc(100vh - 525.66px);
    padding: 32px 0 56px;
}

.project-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.texts {
    padding: 0 80px;
}

.desc-text {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 123%;
    margin: 16px 0 24px;
    max-width: 1280px;
    white-space: pre-line;
}

@media screen and (max-width: 991px) {
    .shevchenko-page {
        padding: 32px 0 0;
    }

    .texts {
        padding: 0 24px;
    }

    .navigation-shevchenko {
        margin: 0;
        width: 100%;
        display: block;
    }

    .row-images {
        width: 100% !important;
        margin-bottom: 16px;
    }

    .ant-image .ant-image-img {
        vertical-align: unset;
    }
}