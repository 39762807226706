@font-face {
    font-family: 'Euclid Flex';
    src: local('Euclid Flex Medium'), local('Euclid-Flex-Medium'),
    url('EuclidFlex-Medium.woff2') format('woff2'),
    url('EuclidFlex-Medium.woff') format('woff'),
    url('EuclidFlex-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Euclid Flex Regular';
    src: local('Euclid Flex Regular'), local('Euclid-Flex-Regular'),
    url('EuclidFlex-Regular.woff2') format('woff2'),
    url('EuclidFlex-Regular.woff') format('woff'),
    url('EuclidFlex-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Regular'), local('Gilroy-Regular'),
    url('Gilroy-Regular.woff2') format('woff2'),
    url('Gilroy-Regular.woff') format('woff'),
    url('Gilroy-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Source Code Pro';
    src: local('Source Code Pro Bold'), local('Source-Code-Pro-Bold'),
    url('SourceCodePro-Bold.woff2') format('woff2'),
    url('SourceCodePro-Bold.woff') format('woff'),
    url('SourceCodePro-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
