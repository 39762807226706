.management-page {
    padding-top: 32px;
    background: #F8F8F8;
    padding-bottom: 56px;
    min-height: calc(100vh - 525.66px);
}

.management-title {
    font-family: 'Euclid Flex Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 123%;
    margin-bottom: 16px;
}

.photo {
    width: 520px;
    height: 480px;
}

@media screen and (min-width: 1200px) and (max-width: 1256px) {
    .photo {
        width: 492px !important;
    }
}

@media screen and (max-width: 991px) {
    .management-page {
        padding-bottom: 20px;
    }

    .management-title {
        font-size: 28px;
        margin: 16px 24px 0;
    }

    .photo {
        width: 100%;
        height: 236px;
        margin: 12px 0 0;
    }

    .navigation {
        margin: 0 24px;
    }
}