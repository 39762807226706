.header {
    padding: 20px 80px 20px 80px;
    width: 100vw;
}

.navButton {
    margin-right: 32px;
}

.active {
    font-weight: 700;
}

.ant-select-selector {
    border: 1px solid #00ACDF !important;
    border-radius: 16px !important;
}

.ant-select-dropdown {
    border-radius: 16px !important;
}

.ant-select-item-option-selected {
    background: #00ACDF !important;
    color: #FFFFFF !important;
}

.ant-select-arrow {
    color: unset !important;
}

.nav-text:hover {
    color: #0073B7 !important;
}

@media screen and (max-width: 991px) {
    .header {
        padding: 16px 20px;
    }

}
