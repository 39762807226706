.App {
    height: 100vh;
    overflow-x: hidden;
}


.font-euclid-flex {
    font-family: 'Euclid Flex Regular';
}

.font-euclid-flex-medium {
    font-family: 'Euclid Flex';
}

.font-gilroy {
    font-family: 'Gilroy';
}

.font-source-code-pro {
    font-family: 'Source Code Pro';
}

.title-40 {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 123%;
}

.title-32 {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 123%;
}

.title-24 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 123%;
}

.p-20 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 123%;
}

.p-18 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 123%;
}

.mb-1 {
    margin-bottom: 4px;
}

.mb-2 {
    margin-bottom: 8px;
}

.mb-3 {
    margin-bottom: 16px;
}

.mb-4 {
    margin-bottom: 24px;
}

.white-text {
    color: #F8F8F8;
}

.dark-blue-text {
    color: #0E2C58 !important;
}