.our-results-title {
    font-family: "Euclid Flex";
    margin: 56px 80px 16px;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 123%;
}

.right-arrow-results {
    position: absolute;
    top: 223px;
    right: 32px;
    width: 56px;
    height: 56px;
    background: rgba(14, 44, 88, 0.7);
    border-radius: 50%;
}

.right-arrow-results:hover {
    background: rgba(0, 115, 183, 0.7);
}

.left-arrow-results {
    position: absolute;
    top: 223px;
    left: 32px;
    width: 56px;
    height: 56px;
    background: rgba(14, 44, 88, 0.7);
    border-radius: 50%;
}

.left-arrow-results:hover {
    background: rgba(0, 115, 183, 0.7);
}

.left-arrow-results-icon {
    width: 17.5px;
    height: 25px;
    margin-top: 15px;
    margin-left: -6px;
}

.right-arrow-results-icon {
    width: 17.5px;
    height: 25px;
    margin-top: 15px;
    margin-left: 6px;
}

@media screen and (max-width: 991px) {
    .our-results-title {
        font-size: 32px;
        margin: 64px 24px 32px;
    }

    .left-arrow-results {
        position: absolute;
        top: 102px;
        left: 8px;
        width: 32px;
        height: 32px;
    }

    .right-arrow-results {
        position: absolute;
        top: 102px;
        right: 8px;
        width: 32px;
        height: 32px;
    }

    .left-arrow-results-icon {
        width: 10px;
        height: 14px;
        margin-top: 8px;
        margin-left: -2px;
    }

    .right-arrow-results-icon {
        width: 10px;
        height: 14px;
        margin-top: 8px;
        margin-left: 2px;
    }


    .button-all-projects {
        width: 335px;
    }

    .our-results {
        margin-bottom: 32px;
    }

    .text-on-carousel {
        top: 180px;
    }

    .text-before {
        left: calc(25vw - 15px);
    }

    .text-after {
        right: calc(25vw - 35px);
    }
}