.main-page-title-text {
    font-weight: 700;
    font-size: 64px;
    line-height: 81px;
    color: #F8F8F8;
    white-space: pre-line;
    margin-top: 0;
    margin-bottom: 16px;
}

.bg-image {
    width: 100vw;
    height: calc(100vh - 83px);
    background: url("../../utils/images/main.png");
    background-size: 100vw 100vh;
}

.main-page-container {
    margin: 0 80px 56px;
    height: calc(100vh - 80px);
    width: 100vw;
    position: relative;
}

.main-page-description-text {
    font-weight: 500;
    font-size: 18px;
    line-height: 123%;
    color: #F8F8F8;
    white-space: pre-line;
    max-width: 836px;
}

.buttons {
    background: #00ACDF;
    border-radius: 100px;
    width: 289px;
    height: 50px;
    border: 1px solid #00ACDF;
}

.buttons:hover {
    background: #0073B7;
    border: 1px solid #0073B7;
}

.button-text {
    font-family: "Euclid Flex Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 123%;
    color: #F8F8F8;
}

.contact-us {
    background: transparent;
    margin-left: 16px;
    color: #F8F8F8;
}

.contact-us:hover {
    background: #00ACDF;
}

.main-page-content {
    position: absolute;
    bottom: 56px;
}

.our-company {
    margin: 56px 80px 12px;
}

.our-company-title {
    font-family: "Euclid Flex";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 123%;
    margin-bottom: 16px;
}

.small-title {
    font-family: 'Euclid Flex';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 123%;
    color: #0073B7;
    margin-bottom: 8px;
}

.our-results {
    text-align: center;
    position: relative;
    margin-bottom: 56px;
}

.left-arrow {
    position: absolute;
    top: 223px;
    left: 32px;
    width: 56px;
    height: 56px;
    background: rgba(14, 44, 88, 0.7);
    border-radius: 50%;
}

.left-arrow:hover {
    background: rgba(0, 115, 183, 0.7);
}

.left-arrow-icon {
    width: 17.5px;
    height: 25px;
    margin-top: 15px;
    margin-left: -6px;
}

.right-arrow {
    position: absolute;
    top: 223px;
    right: 32px;
    width: 56px;
    height: 56px;
    background: rgba(14, 44, 88, 0.7);
    border-radius: 50%;
}

.right-arrow:hover {
    background: rgba(0, 115, 183, 0.7);
}

.right-arrow-icon {
    width: 17.5px;
    height: 25px;
    margin-top: 15px;
    margin-left: 6px;
}


.text-on-carousel {
    position: absolute;
    font-family: 'Gilroy';
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    top: 439px;
    color: #F8F8F8;
}

.text-before {
    left: calc(25vw - 30px);
}

.text-after {
    right: calc(25vw - 70px);
}

.dots-buttons {
    background: #0E2C58;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    margin-right: 8px;
}

.dots-buttons:hover {
    background: #0073B7;
}

.last-dot {
    margin-right: 0;
}

.active-dot {
    background: #00ACDF;
}

.slider-dots-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 24px;
}

.button-all-projects {
    width: 350px;
}

.conclusion {
    background: #0E2C58;
    margin: 0 auto 56px;
    padding: 56px 0;
    text-align: center;
}

.conclusion-text {
    font-family: 'Euclid Flex';
    color: #F8F8F8;
    margin: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 123%;
}

.download-pdf-button {
    width: 350px;
    background: transparent;
    border: 1px solid #00ACDF;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 16px auto 0;
}

.download-pdf-button:hover {
    border: 1px solid #0073B7;
}

.work-process {
    margin: 56px 80px;
    position: relative;
}

.work-process-title {
    font-family: "Euclid Flex";
    margin-bottom: 16px;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 123%;
}

.work-process-description {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 123%;
}

.steps {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
}

.step-number {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #0E2C58;
    font-family: 'Source Code Pro';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 23px;
    color: white;
    margin-right: 16px;
    z-index: 100;
}

.step-info {
    max-width: 844px;
}

.step-title {
    font-family: 'Euclid Flex';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 8px;
}

.step-description {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 123%;
    margin-bottom: 12px;
    margin-top: 0px;
}

.vertical-divider {
    background-color: #00ACDF;
    width: 2px;
    height: 600px;
    position: absolute;
    top: 115px;
    left: 16px;
}

@media screen and (max-width: 991px) {
    .bg-image {
        height: calc(100vh - 55px);
        background: linear-gradient(0deg, #000000 33.73%, rgba(0, 0, 0, 0) 100%), url("../../utils/images/main-mobile.png");
        background-repeat: no-repeat;
        background-size: 100vw 100vh;
    }

    .main-page-title-text {
        font-weight: 700;
        font-size: 32px;
        line-height: 41px;
        margin-bottom: 12px;
        max-width: 327px;
    }

    .main-page-container {
        margin: 0 24px 48px;
        height: calc(100vh - 55px);
    }

    .main-page-content {
        bottom: 48px;
    }

    .main-page-description-text {
        font-weight: 400;
        margin: 0 0 12px;
        max-width: 327px;
    }

    .buttons {
        width: 155px;
    }

    .button-text {
        font-size: 16px;
        line-height: 20px;
    }

    .contact-us {
        padding: 4px;
    }

    .our-company {
        margin: 32px 24px;
    }

    .our-company-title {
        font-size: 32px;
    }

    .small-title {
        color: unset;
    }

    .conclusion-text {
        font-size: 18px;
        max-width: 335px;
    }

    .conclusion {
        padding: 32px 16px 32px 24px;
        maring: unset;
        text-align: left;
        margin-bottom: 0;
    }

    .download-pdf-button {
        width: 335px;
    }

    .work-process {
        margin: 32px 24px 20px;
        position: relative;
    }

    .work-process-title {
        font-size: 32px;
    }

    .work-process-description {
        font-size: 16px;
    }

    .step-title {
        max-width: 232px;
    }

    .step-description {
        max-width: 279px;
        font-size: 18px;
    }

    .mobile-nav {
        padding: 32px 24px;
    }

    .mobile-nav-links {
        font-family: "Euclid Flex Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 123%;
        color: #0E2C58;
    }

    .bottom-lang-buttons {
        display: flex;
        flex-wrap: wrap;
        margin-top: 56px;
    }

    .lang-button {
        width: 50px;
        padding: 10px 8px;
        border: 1px solid #00ACDF;
        text-align: center;

    }

    .active-button {
        color: #FFFFFF;
        background: #00ACDF;
    }

}