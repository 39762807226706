.footer {
    background: #0E2C58;
    padding: 56px 80px;
}

.footer-nav {
    font-family: 'Euclid Flex Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 123%;
    color: #F8F8F8;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
}

.active {
    font-weight: 700;
}

.footer-icon:hover {
    filter: invert(34%) sepia(68%) saturate(6213%) hue-rotate(186deg) brightness(90%) contrast(101%)
}

.address-title {
    font-family: 'Euclid Flex Regular';
    font-weight: 500;
    font-size: 18px;
    line-height: 123%;
    color: #F8F8F8;
    max-width: 339px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.address-description {
    font-family: 'Gilroy';
    font-weight: 400;
    font-size: 16px;
    line-height: 123%;
    color: #F8F8F8;
    white-space: pre-line;
    max-width: 339px;
}

.contacts {
    font-family: 'Euclid Flex Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 123%;
    color: #F8F8F8;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: right;
}

.bold-text {
    font-weight: 700;
}

.black-text {
    color: #000000;
}

@media screen and (max-width: 991px) {
    .footer {
        padding: 32px 24px;
    }

    .contacts {
        justify-content: left;
    }

    .address-title {
        font-family: 'Euclid Flex';
        font-weight: 800;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: left;
        margin: unset;
    }

    .address-description {
        font-family: 'Gilroy';
        font-weight: 400;
        font-size: 16px;
        line-height: 123%;
        color: #F8F8F8;
        white-space: pre-line;
        max-width: 339px;
    }

    .nav-text:hover {
        color: #0073B7 !important;
    }

    .black-text {
        color: #000000;
    }
}