.project-page {
    background: #F8F8F8;
    min-height: calc(100vh - 525.66px);
    padding: 32px 80px 56px;
}

.project-card {
    background: #0E2C58;
    border-radius: 32px;
    width: calc(100% - 32px);
    max-height: 355px;
    padding: 16px;
}

.project-card:hover {
    background: #0073B7;
}

.project-card:hover p {
    color: #FFFFFF;
}

.project-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.card-title {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 123%;
    color: #F8F8F8;
    margin: 12px 0 4px;
}

.card-performance {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 123%;
    color: #00ACDF;
    margin: 0;
}

.projects-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media screen and (max-width: 991px) {
    .navigation-projects {
        margin: 0;
        width: 100%;
        display: block;
    }

    .project-page {
        padding: 32px 16px 8px;
    }

    .projects-row {
        display: block;
        flex-wrap: unset;
    }

    .project-card {
        margin-bottom: 24px;
    }

}