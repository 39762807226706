.manufacture-block {
    background: #F8F8F8;
}

.treatment-page {
    padding-top: 32px;
    background: #F8F8F8;
}

.navigation {
    font-family: 'Euclid Flex Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 123%;
    color: #0E2C58;
}

.treat-desc {
    white-space: pre-line;
    max-width: 1172px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 123%;
    margin-bottom: 16px;
}

.our-manufacture {
    text-align: center;
    position: relative;
    padding-bottom: 40px;
    margin-top: 16px;
}

.treatment-facilities-block {
    /*padding: 56px 80px;*/
    background: #0E2C58;
}


.left-arrow {
    position: absolute;
    top: 372px;
    left: 32px;
    width: 56px;
    height: 56px;
    background: rgba(14, 44, 88, 0.7);
    border-radius: 50%;
}

.left-arrow:hover {
    background: rgba(0, 115, 183, 0.7);
}

.left-arrow-icon {
    width: 17.5px;
    height: 25px;
    margin-top: 15px;
    margin-left: -6px;
}

.right-arrow {
    position: absolute;
    top: 372px;
    right: 32px;
    width: 56px;
    height: 56px;
    background: rgba(14, 44, 88, 0.7);
    border-radius: 50%;
}


.dots-buttons-white {
    background: #F8F8F8;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    margin-right: 8px;
}

.active-dot {
    background: #00ACDF;
}

.dots-buttons-white:hover {
    background: #0073B7;
}


@media screen and (max-width: 991px) {
    .navigation-treat {
        margin: 0;
        width: 100%;
        display: block;
    }

    .right-arrow {
        top: 105px;
        width: 32px;
        height: 32px;
        right: 8px;
    }

    .left-arrow {
        top: 105px;
        width: 32px;
        height: 32px;
        left: 8px;
    }

    .left-arrow-icon {
        width: 10px;
        height: 14px;
        margin-top: 8px;
        margin-left: -2px;
    }

    .right-arrow-icon {
        width: 10px;
        height: 14px;
        margin-top: 8px;
        margin-left: 2px;
    }
}